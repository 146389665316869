import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';
import { Divider, Form, Header, Icon } from 'semantic-ui-react';
import formHOC from '../../../theme/form/formHOC';


const metaInfo = {
  store: 'kitStore',
  form: 'KIT_FRM',
  resetErrors: true,
};

class ValidateKit extends Component {
  state = { loading: false }
  constructor(props) {
    super(props);
    const { registerKit, kitStore } = this.props;
    if (!registerKit) {
      kitStore.setFormKeyData('KIT_FRM', 'toc', 'optional', 'rule');
    } else {
      kitStore.setFormKeyData('KIT_FRM', 'toc', 'accepted', 'rule');
    }
  }

  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { registerKit, kitStore, authStore } = this.props;
      const valid = await kitStore.checkKitId();
      if (get(valid, 'isValid')) {
        kitStore.setIntakeFormDetails(get(valid, 'panelMeta'));
        this.props.stepHandler((registerKit || authStore.isUserLoggedIn) ? 'USER_DETAILS' : 'EMAIL_PASS');
      } else {
        this.props.uiStore.setErrors({ message: 'Please enter valid kit id.' });
      }
      this.setState({ loading: false });
    } catch {
      this.setState({ loading: false });
    }
  }

  render() {
    const { smartElement, registerKit, authStore } = this.props;
    const { brandMeta } = authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    const color = get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69';
    return (
      <>
        <Header 
          as="h2" 
          className="mb-30" 
          textAlign={!registerKit ? 'center' : ''}
          style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >
          {registerKit ? get(brandMeta, 'additionalKitTitle') || 'Register Another Test Kit' : get(brandMeta, 'signup1Title') || 'Enter your test kit registration ID below'}
          {registerKit && get(brandMeta, 'additionalKitDesc') ? <Header.Subheader>{get(brandMeta, 'additionalKitDesc')}</Header.Subheader> : ''}
          {!registerKit && get(brandMeta, 'signup1Desc') ? <Header.Subheader>{get(brandMeta, 'signup1Desc')}</Header.Subheader> : ''}
        </Header>
        <Divider section />
        <Form error onSubmit={this.onSubmit}>
          {smartElement.Input('kitId', { size: 'huge', action:!registerKit, autoFocus: true, buttonProps: registerKit ? false : { content: <Icon name="right arrow" />, primary: true, disabled: !smartElement.FormValid(), loading: this.state.loading } })}
          {registerKit ? smartElement.FormCheckBoxOrRadio('toc', { className: 'large-text', singleCheck: true, linkLabelWrapper: get(brandMeta, 'tocUrl') || null }) : ''}
          {smartElement.Errors()}
          <div className={`mt-30 ${registerKit ? 'right-align' : 'center-align'}`}>
            {registerKit ? smartElement.Button({ color, content: <>Register Kit <Icon name="chevron circle right" /></>, loading: this.state.loading }) : null}
            {!(registerKit || authStore.isUserLoggedIn) ? <p className="muted-text mt-30">Already have an account? <Link to="/login" >Log In</Link></p> : ''}
          </div>
        </Form>
      </>
    );
  }
}
export default inject('kitStore', 'uiStore', 'authStore')(withRouter(formHOC(observer(ValidateKit), metaInfo)));

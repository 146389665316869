import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Header, Button, Grid, Icon, Popup } from 'semantic-ui-react';
import AssetImage from '../../shared/AssetImage';
import { Image64 } from '../../../theme/shared';

class Dashboard extends React.Component {
  state = { pdfError: null }
  constructor(props) {
    super(props);
    if (this.props.authStore.isUserLoggedIn) {
      this.props.kitStore.allOrders();
    }
    this.props.kitStore.resetDynamicForm();
  }
  showMessageHandler = (order) => {
    // if (get(order, 'resultsPdfInfo.id') && get(order, 'outreachStatus') !== 'PENDING' && ['MD_RELEASED', 'EXPEDITED_RELEASE'].includes(get(order, 'status'))) {
    // get(order, 'resultsPdfAttachmentsInfo[0]') &&
    if (get(order, 'orderId') && ['MD_RELEASED', 'EXPEDITED_RELEASE'].includes(get(order, 'status'))) {
      this.goToOrder(get(order, 'orderId'));
    } else {
      this.setState({ pdfError: get(order, 'orderId') });
      setTimeout(() => {
        this.setState({ pdfError: null });
      }, 3000);
    }
  }
  goToOrder = (orderId) => this.props.history.push(`/dashboard/order/${orderId}`);
  showWarning = (order) => {
    if(order.status === 'LAB_DECLINED' || order.status === 'CANCELED' || order.status === 'MD_DECLINED') {
      return true;
    }
    return false;
  }
  render() {
    const getDateFormatted = date => (date ? moment(date).format('MM/DD/YYYY') : 'Pending');
    const { getKitOrders } = this.props.kitStore;
    const { upToTablet } = this.props.uiStore.responsiveVars;
    const { brandMeta } = this.props.authStore;
    const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
    return (
      <section className="full-section d-flex jcc">
        <Container text>
          <div className="page-header-wrap">
            <Header as="h1" style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}>
              {get(brandMeta, 'dashboardTitle') || 'My Tests'}
              {get(brandMeta, 'dashboardDesc') ? <Header.Subheader>{get(brandMeta, 'dashboardDesc')}</Header.Subheader> : ''}
            </Header>
            <Button 
              primary 
              as={Link} 
              to="/dashboard/register-kit"
              style={{ 'backgroundColor': get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
            >
              Register another test <Icon name="plus circle" className="right-side" />
            </Button>
          </div>
          <div className="order-list">
            {get(getKitOrders, '[0]') ? getKitOrders.map(order => {
              const panelMeta = get(order, 'panel.meta') ? JSON.parse(get(order, 'panel.meta')) : {};
              const labActivity = (get(order, 'activity') || []).find(a => a.type === 'LAB_ACCEPTED');
              const mdActivity = (get(order, 'activity') || []).find(a => a.type === 'EXPEDITED_RELEASE' || a.type === 'MD_RELEASED');
              return (
                <div className="order-wrap" style={{ backgroundColor : this.showWarning(order) === true ? 'rgb(224 224 224)' : 'transparent'}} onClick={() => this.showMessageHandler(order)}>
                  <div className="img-holder">
                    {get(panelMeta, 'panelImage.url') ?
                      <Image64 bg={!upToTablet} className="img-wrap" srcUrl={get(panelMeta, 'panelImage.url')} width="300" centered alt={get(brandMeta, 'brandTitle') || 'LiveSmart'} /> :
                      <AssetImage bg={!upToTablet} className="img-wrap" centered path="logo_icon.png" width="300" alt={get(brandMeta, 'brandTitle') || 'LiveSmart'} />}
                  </div>
                  {/* <div className="order-details" style={this.showWarning(order) === true ? { backgroundClolor: 'grey'} : {''}}> */}
                  <div className="order-details">
                    {
                      this.showWarning(order) && (
                      <div style={{ float: 'right', alignSelf: 'end'}}>
                        <Popup content='Please contact us for next steps' trigger={<Icon name="warning circle" color='yellow' />} />
                      </div>
                      )
                    }
                    
                    <Header 
                      as="h2"
                      style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
                    >{get(panelMeta, 'panelName') || get(order, 'panel.name')} {get(panelMeta, 'panelPrefix') ? <span>({get(panelMeta, 'panelPrefix')})</span> : ''}
                      <Header.Subheader>Order ID: {get(order, 'orderId')}</Header.Subheader>
                      {get(panelMeta, 'panelDescriptions') ? <Header.Subheader>{get(panelMeta, 'panelDescriptions')}</Header.Subheader> : ''}
                      {this.state.pdfError === get(order, 'orderId') ? <Header.Subheader style={{ color: 'red' }}><Icon name="warning circle" />The results for this test are not yet ready.</Header.Subheader> : ''}
                    </Header>
                    <Grid columns={3} className="order-meta">
                      <Grid.Column>
                        <Header sub>Registered on</Header>
                        <span>{getDateFormatted(get(order, 'createdAt'))}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Lab received on</Header>
                        <span style={{fontWeight: this.showWarning(order) === true ? 'bold' : 'normal'}}>{this.showWarning(order) === true ? '- - -' :  getDateFormatted(get(labActivity, 'createdAt'))}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Result received on</Header>
                        <span style={{fontWeight: this.showWarning(order) === true ? 'bold' : 'normal'}}>{this.showWarning(order) === true ? '- - -' :  getDateFormatted(get(mdActivity, 'createdAt'))}</span>
                      </Grid.Column>
                    </Grid>
                  </div>
                </div>
              )
            }) : ''}
          </div>
        </Container>
      </section>
    );
  }
}

export default inject('kitStore', 'authStore', 'uiStore')(withRouter(observer(Dashboard)));
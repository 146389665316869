import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Icon, Item, Popup, Input, Grid } from 'semantic-ui-react';
import { find, isNull, toUpper } from 'lodash';
import { MARKER_RANGE_TYPE as RANGES, MARKER_READING_CONFIG } from './constant/marker';

class Results extends React.Component {
  state = { currTime: null };
  readingChange = (e, i) => {
    const { markers, setMarkers } = this.props;
    const reading = e.target.value;
    markers[i].reading = reading;
    setMarkers(markers);
    const currTime = + new Date();
    this.setState({ currTime });
  }
  isReadingValid = (r, emptyChk = false, allowString = false) => {
    if (allowString) {
      const pattern = /^[0-9a-zA-Z ,-_]*$/;
      return pattern.test(r);
    } else {
      if (r === 'QNS' || r === 'TNP') {
        return true;
      }
      const isWithComparisonOperator = reading => {
        const pattern = /[=><!]{1,2}\s\d+/;
        return pattern.test(reading) ? parseFloat(reading.replace(/[<>=! ]/gi, '')) : parseFloat(reading);
      }
      const rFloat = r ? isWithComparisonOperator(r) : -1;
      if (emptyChk) {
        return rFloat >= 0;
      }
      return r === '' || r === null || rFloat >= 0;
    }
  };
  processIfLabCanComplete = (markers, status) => {
    // hack to disable lab release if markers are invalid
    if (markers && markers.length > 0 && markers.find(m => !this.isReadingValid(m.reading, true)) && status === 'LAB_ACCEPTED'
      && !this.props.editing) {
      setTimeout(() => {
        const item = document.querySelectorAll('.action_Complete')[0];
        if (item) item.setAttribute('style', 'display: none;');
      }, 800);
    }
  }
  checkValid = (i, allowString = false) => {
    const { markers, setMarkers } = this.props;
    markers[i].readingIsValid = this.isReadingValid(markers[i].reading, false, allowString);
    setMarkers(markers);
    const currTime = + new Date();
    this.setState({ currTime });
  }
  getClas = ({ rangeType }) => {
    const markerIndicator = { icon: 'blue', class: '' };
    const { data } = this.props;
    let metadata = data?.brand?.meta ? JSON.parse(data.brand.meta) : {};
    let showMarkerColor = metadata.dontShowMarkerColors || false;
    if(showMarkerColor) {
      if (rangeType === 'CRITICAL') {
        return { icon: 'red', class: 'error' };
      } 
      else if (rangeType === 'ABNORMAL') {
        return { icon: 'orange', class: 'warning' };
      }
    }
    
    return markerIndicator;
  }
  getClassName = (r) => {
    if (r.rangeType === 'CRITICAL') {
      return 'range-critical';
    } else if (r.rangeType === 'ABNORMAL') {
      return 'range-abnormal';
    } else {
      return '';
    }
  }
  render() {
    const { data, editing, markers, orderStatus } = this.props;
    console.log('this.props=== ', this.props);
    const invalidMakersState = ['LAB_RELEASED', 'MD_REVIEW', 'MD_RELEASED', 'OUTREACH PENDING', 'OUTREACH SUCCESSFUL', 'OUTREACH UNSUCCESSFUL'].includes(orderStatus)
    this.processIfLabCanComplete(data.markers, data.status);
    return (
      <Grid>
        <Grid.Column width={16} >
          {/* <Header as='h3'>
            <Header.Subheader className="uppercase">RESULTS</Header.Subheader>
          </Header> */}
  
          {/* <Header as="h4">RESULTS</Header> */}
        </Grid.Column>
        <Grid.Column width={16} >
          <Item.Group className="results">
            {data.markers ? data.markers.map((m, i) => {
              const hideInvalidMakers = invalidMakersState ? !(isNull(m.reading) || toUpper(m.reading) === 'DNR' || m.reading === '') : true;
              return hideInvalidMakers ?
                <Item>
                  {/* <Icon size="small" name="circle" color={this.getClas(m).icon} /> */}
                  <Item.Content verticalAlign='middle'>
                    <Item.Header>{m.name}</Item.Header>
                    <Item.Extra className={this.getClas(m).class}>
                      <span className="status text-uppercase">
                        {/* {m.isCritical && 'Critical'} */}
                      </span>
                      {editing ? (
                        <Input
                          size="mini"
                          name="reading"
                          maxLength={MARKER_READING_CONFIG.MAX_LIMIT}
                          error={markers[i].readingIsValid === false}
                          onChange={(e) => { this.readingChange(e, i) }}
                          onBlur={() => this.checkValid(i, find(m.ranges, { type: 'EQUAL_STRING' }) ? true : false)}
                          value={markers[i] ? markers[i].reading : ''}
                        />
                      ) : <span className="result-number">{m.reading}</span>}
                      {m.reading ? m.units : ''}
                    </Item.Extra>
                    {m.rangeRef
                      ? (
                        <Item.Extra className="range">
                          <span className="result-number">
                            {' | '} {m.rangeRef}
                          </span>
                        </Item.Extra>) : null}
                    <Popup trigger={<Icon name='info circle' />} hoverable size='mini' position='top center' className="range" wide>
                      {/* <p>REFERENCE RANGES <span className="pull-right">{m.units}</span></p> */}
                      <dl className="dl-horizontal">
                        <span>
                          <dt>REFERENCE RANGES</dt>
                          <dd>{m.units}</dd>
                        </span>
                        {m.ranges.map(r =>
                          <span className={this.getClassName(r)}>
                            <dt>{RANGES[r.type]} {r.value}</dt>
                            <dd>{r.name}</dd>
                          </span>)}
                      </dl>
                    </Popup>
                    {m.markerNote && (
                      <Popup trigger={<Icon name='file alternate outline circle' />} hoverable size='mini' position='top center' className="range" wide>
                        {m.markerNote}
                      </Popup>
                    )}
                  </Item.Content>
                </Item> : null;
            }) : <Header as="h4">No result to display.</Header>}
          </Item.Group>
        </Grid.Column>
      </Grid>
    )
  }
}

export default inject('uiStore')(observer(Results));
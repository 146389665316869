import React from 'react';
import { get } from 'lodash';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Header } from 'semantic-ui-react';

const Home = (props) => {
  const { brandMeta } = props.authStore;
  const themeClass = get(brandMeta, 'brandsTheme') || 'live-smart';
  const siteDescription = get(brandMeta, 'siteDescription') || 'Please sign in to view results and register additional tests, or register your first test kit to create a new account.';

  return (
    // <section className="full-section d-flex aic jcc center-align">
    <section className={`full-section d-flex aic jcc ${themeClass === 'live-smart' ? 'center-align' : 'ebien-image'}`}>
      <Container text={themeClass === 'live-smart'}>
        <Header as="h1" style={{ 'color': get(brandMeta, 'titleTextColor') ? get(brandMeta, 'titleTextColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}>{get(brandMeta, 'siteTitle') || 'LiveSmart'}</Header>
        <p>{siteDescription}</p>
        <Divider section hidden />
        <Button 
          primary 
          size="large" 
          as={Link} 
          to="/login"
          style={{ 'backgroundColor': get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >Log In</Button>
        <Button 
          primary 
          size="large" 
          as={Link} 
          to="/register"
          style={{ 'backgroundColor': get(brandMeta, 'headerColor') ? get(brandMeta, 'headerColor') : themeClass === 'live-smart' ? '#170c76' : '#484d69'}}
        >New User</Button>
      </Container>
    </section>
  );
};

export default inject('authStore')(observer(Home));
